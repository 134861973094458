<template>
  <div class="row">
    <!-- Brigham City Temple -->
    <Galleries
      v-for="(gallery, index) in galleries"
      :key="index"
      :galleryName="gallery.galleryName"
      :imgUrl="gallery.imgUrl"
      :imgDescription="gallery.imgDescription"
      :galleryLink="gallery.galleryLink"
      :isCollection="gallery.isCollection"
    />
  </div>
</template>

<script>
  import Galleries from '../components/Galleries.vue';

  export default {
    name: 'Home',
    props: {
      galleries: {
        type: Array,
        required: true,
      },
    },
    components: {
      Galleries,
    },
  };
</script>

<style scoped>
  .row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
    margin: 3rem;
  }

  /* Tablets and smaller */
  @media only screen and (max-width: 1024px) {
    .row {
      display: grid;
      grid-template-columns: auto;
      column-gap: 2rem;
      row-gap: 2rem;
      margin: 3rem;
    }
  }

  /* Mobile */
  @media only screen and (max-width: 414px) {
    .row {
      margin: 1.5rem;
    }
  }
</style>
