<template>
  <GalleryTemplates :galleries="galleries" />
</template>

<script>
  import GalleryTemplates from '../components/GalleryTemplates.vue';

  export default {
    name: 'Home',
    data() {
      return {
        galleries: [
          {
            galleryName: 'Brigham City Temple',
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/brigham_city_temple.jpeg',
            imgDescription: 'Brigham City Temple',
            galleryLink:
              'https://yourcustomgallery.com/gallery/d9f68112-6673-4aae-ae02-3be4d65e3d98/',
            isCollection: false,
          },
          {
            galleryName: 'Idaho Falls Idaho Temple',
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/idaho_falls_temple.jpeg',
            imgDescription: 'Idaho Falls Idaho Temple',
            galleryLink:
              'https://yourcustomgallery.com/gallery/d52b8786-632d-4022-aeee-7c5d977dbd20/',
            isCollection: false,
          },
          {
            galleryName: 'Logan Utah Temple',
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/logan_utah_temple.jpeg',
            imgDescription: 'Logan Utah Temple',
            galleryLink:
              'https://yourcustomgallery.com/gallery/b2ee6ddf-40d6-4b17-a9ad-43576bd1db27/',
            isCollection: false,
          },
          {
            galleryName: 'Pocatello Idaho Temple',
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/pocatello_idaho_temple.jpeg',
            imgDescription: 'Pocatello Idaho Temple',
            galleryLink:
              'https://yourcustomgallery.com/gallery/7b946e2d-755a-4984-92a3-2b1a6a1f41e9/',
            isCollection: false,
          },
          {
            galleryName: 'Provo City Center Temple',
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/provo_city_center_temple.jpeg',
            imgDescription: 'Provo City Center Temple',
            galleryLink:
              'https://yourcustomgallery.com/gallery/7e0b8d6f-cec3-4d0f-9eed-8c1c1668a550/',
            isCollection: false,
          },
          {
            galleryName: 'Salt Lake City Temple',
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/salt_lake_temple.jpeg',
            imgDescription: 'Salt Lake City Temple',
            galleryLink:
              'https://yourcustomgallery.com/gallery/95d8545c-68da-4a5a-971d-fef1ea33b822/',
            isCollection: false,
          },
        ],
      };
    },
    components: {
      GalleryTemplates,
    },
  };
</script>

<style scoped></style>
