<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer id="footer" />
  </div>
</template>

<script>
  import Header from './components/Header.vue';
  import Footer from './components/Footer.vue';

  export default {
    name: 'App',
    components: {
      Header,
      Footer,
    },
  };
</script>

<style>
  #app {
    font-family: 'Nanum Myeongjo', serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  #footer {
    margin-top: auto;
  }
</style>
