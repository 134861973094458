<template>
  <header>
    <img
      style="width: 20rem"
      src="../assets/logo.png"
      alt="Brian J Riley Photography"
      @click="goHome()"
    />
    <div id="logos">
      <a href="https://www.facebook.com/Brian-J-Riley-Photography-103863332033478" target="_blank">
        <img class="social" id="facebook" src="../assets/facebook_logo.png" alt="Facebook" />
      </a>
      <a href="https://www.instagram.com/viewthegood/" target="_blank">
        <img class="social" src="../assets/instagram_logo.png" alt="Instagram" />
      </a>
    </div>
  </header>
</template>

<script>
  export default {
    name: 'Header',
    methods: {
      goHome() {
        if (this.$route.name === 'Home') {
          return;
        } else {
          this.$router.push('/');
        }
      },
    },
  };
</script>

<style scoped>
  header {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    margin: 3rem;
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
  }
  img:hover {
    cursor: pointer;
  }
  #logos {
    display: flex;
  }
  a {
    display: flex;
    align-content: center;
  }
  .social {
    height: 30px;
    width: 30px;
    margin: auto;
  }
  #facebook {
    padding: 0 1rem;
  }

  /* Mobile */
  @media only screen and (max-width: 414px) {
    header {
      margin: 1.5rem;
      display: grid;
      align-content: center;
      padding-bottom: 1rem;
    }
    img {
      max-width: 100%;
    }
    #logos {
      display: none;
    }
  }
</style>
