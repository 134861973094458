import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Landscapes from '../views/Landscapes.vue';
import Nature from '../views/Nature.vue';
import Temples from '../views/Temples.vue';
import Wildlife from '../views/Wildlife.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/landscapes',
    name: 'landscapes',
    component: Landscapes,
  },
  {
    path: '/nature',
    name: 'nature',
    component: Nature,
  },
  {
    path: '/temples',
    name: 'temple',
    component: Temples,
  },
  {
    path: '/wildlife',
    name: 'wildlife',
    component: Wildlife,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
