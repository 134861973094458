<template>
  <GalleryTemplates :galleries="galleries" />
</template>

<script>
  import GalleryTemplates from '../components/GalleryTemplates.vue';

  export default {
    name: 'Home',
    data() {
      return {
        galleries: [
          {
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/nature.jpg',
            imgDescription: 'Flowers',
            galleryLink:
              'https://yourcustomgallery.com/gallery/05898e60-b651-4952-ae4b-5a8b22df151f/',
            galleryName: 'Flowers',
            isCollection: false,
          },
          {
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/insects.jpeg',
            imgDescription: 'Insects',
            galleryLink:
              'https://yourcustomgallery.com/gallery/3877d576-a504-4a59-bdd3-ac7bc7fa7c2d/',
            galleryName: 'Insects',
            isCollection: false,
          },
        ],
      };
    },
    components: {
      GalleryTemplates,
    },
  };
</script>

<style scoped></style>
