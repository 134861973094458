<template>
  <div>
    <GalleryTemplates :galleries="galleries" />
  </div>
</template>

<script>
  import GalleryTemplates from '../components/GalleryTemplates.vue';

  export default {
    name: 'Home',
    data() {
      return {
        galleries: [
          {
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/california_landscapes.jpeg',
            imgDescription: 'California Landscapes',
            galleryLink:
              'https://yourcustomgallery.com/gallery/92732409-3004-458e-8e82-a379b1f7738e/',
            galleryName: 'California Landscapes',
            isCollection: false,
          },
          {
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/idaho_landscapes.jpeg',
            imgDescription: 'Idaho Landscapes',
            galleryLink:
              'https://yourcustomgallery.com/gallery/c4c44ccd-ebeb-41b9-9aad-edc151596227/',
            galleryName: 'Idaho Landscapes',
            isCollection: false,
          },
          {
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/utah_landscapes.jpeg',
            imgDescription: 'Utah Landscapes',
            galleryLink:
              'https://yourcustomgallery.com/gallery/d5c4b3a8-b061-4fc7-b54a-3ee7452024fb/',
            galleryName: 'Utah Landscapes',
            isCollection: false,
          },
          {
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/wyoming_landscapes.jpg',
            imgDescription: 'Wyoming Landscapes',
            galleryLink:
              'https://yourcustomgallery.com/gallery/e196a285-8281-4978-af6b-9b645cac1a35/',
            galleryName: 'Wyoming Landscapes',
            isCollection: false,
          },
        ],
      };
    },
    components: {
      GalleryTemplates,
    },
  };
</script>

<style scoped>
  h1 {
    margin-left: 3rem;
  }
</style>
