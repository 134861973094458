<template>
  <div class="img-container" oncontextmenu="return false;" @click="sendToGallery()">
    <img :src="imgUrl" :alt="imgDescription" />
    <p>{{ galleryName }}</p>
  </div>
</template>

<script>
  export default {
    name: 'Galleries',
    data() {
      return {};
    },
    props: {
      imgUrl: {
        type: String,
        required: true,
      },
      galleryLink: {
        type: String,
        required: true,
      },
      imgDescription: {
        type: String,
        required: true,
      },
      galleryName: {
        type: String,
        required: true,
      },
      isCollection: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      sendToGallery() {
        if (this.isCollection) {
          this.$router.push(this.galleryLink);
          return;
        }

        window.open(this.galleryLink, '_blank').focus();
      },
    },
  };
</script>

<style scoped>
  .img-container {
    cursor: pointer;
  }
  .img-container:hover {
    filter: opacity(80%);
  }
  img {
    width: 100%;
    height: auto;
    border-radius: 1px;
  }
  p {
    text-align: center;
    font-family: 'Nanum Myeongjo', serif;
    font-size: 1.5rem;
  }
</style>
