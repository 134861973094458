<template>
  <GalleryTemplates :galleries="galleries" />
</template>

<script>
  import GalleryTemplates from '../components/GalleryTemplates.vue';

  export default {
    name: 'Home',
    data() {
      return {
        galleries: [
          // Landscapes
          {
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/wyoming_landscapes.jpg',
            imgDescription: 'Landscapes',
            galleryLink: 'landscapes',
            galleryName: 'Landscapes',
            isCollection: true,
          },
          // Nature
          {
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/insects.jpeg',
            imgDescription: 'Nature',
            galleryLink: 'nature',
            galleryName: 'Nature',
            isCollection: true,
          },
          // Temples
          {
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/pocatello_idaho_temple.jpeg',
            imgDescription: 'Temples of The Church of Jesus Christ of Latter-day Saints',
            galleryLink: 'temples',
            galleryName: 'Temples of The Church of Jesus Christ of Latter-day Saints',
            isCollection: true,
          },
          // Wildlife
          {
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/wildlife.jpeg',
            imgDescription: 'wildlife',
            galleryLink: 'wildlife',
            galleryName: 'Wildlife',
            isCollection: true,
          },
        ],
      };
    },
    components: {
      GalleryTemplates,
    },
  };
</script>

<style scoped></style>
