<template>
  <GalleryTemplates :galleries="galleries" />
</template>

<script>
  import GalleryTemplates from '../components/GalleryTemplates.vue';

  export default {
    name: 'Home',
    data() {
      return {
        galleries: [
          {
            imgUrl:
              'https://brian-j-riley-photography-images.s3.us-west-2.amazonaws.com/wildlife.jpeg',
            imgDescription: 'Wildlife',
            galleryLink:
              'https://yourcustomgallery.com/gallery/dafc21c9-2852-4ea6-b974-cc358568e484/',
            galleryName: 'Wildlife',
            isCollection: false,
          },
        ],
      };
    },
    components: {
      GalleryTemplates,
    },
  };
</script>

<style scoped></style>
