<template>
  <footer>
    <div id="logos">
      <a href="https://www.facebook.com/Brian-J-Riley-Photography-103863332033478" target="_blank">
        <img class="social" id="facebook" src="../assets/facebook_logo.png" alt="Facebook" />
      </a>
      <a href="https://www.instagram.com/viewthegood/" target="_blank">
        <img class="social" src="../assets/instagram_logo.png" alt="Instagram" />
      </a>
    </div>
    Copyright &copy; {{ currentYear }} Brian J Riley Photography. All rights reserved.
  </footer>
</template>

<script>
  export default {
    name: 'Footer',
    data() {
      return {};
    },
    computed: {
      currentYear() {
        let date = new Date();
        return date.getFullYear();
      },
    },
  };
</script>

<style scoped>
  footer {
    margin: 3rem;
    text-align: center;
  }

  #logos {
    display: none;
  }

  /* Mobile */
  @media only screen and (max-width: 414px) {
    #logos {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }
    a {
      display: flex;
      align-content: center;
    }
    .social {
      height: 30px;
      width: 30px;
      margin: auto;
    }
    #facebook {
      padding: 0 1rem;
    }
    img:hover {
      cursor: pointer;
    }
  }
</style>
